<template>
  <div class="batchBox">
    <div class="return" @click="back">
      <img class="backImg" src="@/assets/images/back.png" alt />
    </div>

    <el-form
      ref="detail"
      label-position="right"
      label-width="130px"
      :rules="rules"
      :model="detail"
      size="mini"
      class="formBox"
    >
      <el-form-item label="销售部门:" prop="shop_id">
        <el-input v-model="detail.shop_name" class="w200" readonly></el-input>
      </el-form-item>
      <el-form-item label="客户姓名:" prop="uid">
        <el-input v-model="detail.consignee" class="w200" readonly></el-input>
      </el-form-item>
      <el-form-item size="mini" label="开单人 " prop="sale_id">
        <el-select
          v-model="detail.sale_id"
          class="w200"
          filterable
          clearable
          @change="changeSale"
        >
          <el-option
            v-for="item in options.staff"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="退货日期:" prop="deal_time">
        <el-date-picker
          v-model="detail.deal_time"
          type="date"
          class="w200"
          value-format="yyyy-MM-dd"
          placeholder="请选择业务日期"
        />
      </el-form-item>

	  <el-form-item label="业务类型:" prop="business_type">
	    <el-select
	      v-model="detail.business_type"
	      class="w200"
	      clearable
	      placeholder="请选择业务类型"
	      @change = "business_type"
	    >
	      <el-option
	        v-for="item in options.business_type"
	        :key="item.id"
	        :label="item.name"
	        :value="item.id"
	      />
	    </el-select>
	  </el-form-item>

	  <el-form-item size="mini" label="项目明细" prop="business_two_type" v-if="isShowBusinessTwoType">
	      <el-select
	        v-model="detail.business_two_type"
	        placeholder="请选择"
	        class="w200"
	      >
	        <el-option
	          v-for="item in options.business_two_type"
	          :key="item.id"
	          :label="item.name"
	          :value="item.id"
	        />
	      </el-select>
	  </el-form-item>



	  <el-form-item label="项目类型:" prop="project_type">
	    <el-select
	      v-model="detail.project_type"
	      class="w200"
	      clearable
	      placeholder="请选择项目类型"
	    >
	      <el-option
	        v-for="item in options.project_type"
	        :key="item.id"
	        :label="item.name"
	        :value="item.id"
	      />
	    </el-select>
	  </el-form-item>

	  <el-form-item label="订单类型:" prop="order_type">
	    <el-select
	      v-model="detail.order_type"
	      class="w200"
	      clearable
	      placeholder="请选择订单类型"
	    >
	      <el-option
	        v-for="item in options.order_type"
	        :key="item.id"
	        :label="item.name"
	        :value="item.id"
	      />
	    </el-select>
	  </el-form-item>

      <el-form-item label="退货本金" prop="pay_amount">
        <el-input v-model="detail.pay_amount" class="w200" clearable>
          <span slot="append">元</span>
        </el-input>
      </el-form-item>
      <el-form-item label="退货赠金" prop="gift_amount">
        <el-input v-model="detail.gift_amount" class="w200" clearable>
          <span slot="append">元</span>
        </el-input>
      </el-form-item>

      <el-form-item label="退货店转" prop="shop_transfer_amount">
        <el-input v-model="detail.shop_transfer_amount" class="w200" clearable>
          <span slot="append">元</span>
        </el-input>
      </el-form-item>

      <el-form-item label="销售组织" >
        <el-select
          v-model="detail.sales_organization"
          @change="changeSales"
          placeholder="请选择"
          class="w200"
        >
          <el-option
            v-for="item in options.market"
            :key="item.marketKingdeeCode"
            :label="item.marketName"
            :value="item.marketKingdeeCode"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="库存组织" >
        <el-select
          v-model="detail.settlement_organization"
          @change="changeSettlement"
          placeholder="请选择"
          class="w200"
        >
          <el-option
            v-for="item in options.market"
            :key="item.marketKingdeeCode"
            :label="item.marketName"
            :value="item.marketKingdeeCode"
          />
        </el-select>
      </el-form-item>
      <el-form-item size="mini" label="库存部门:">
            <el-input v-model="detail.delivery_name" class="w200" readonly />
          </el-form-item>
      <el-form-item size="mini" label="发货仓库" prop="house_code">
        <el-select
          v-model="detail.house_code"
          class="w200"
          clearable
          @change="changeHouse"
        >
          <el-option
            v-for="item in options.house"
            :key="item.kingdeeCode"
            :label="item.name"
            :value="item.kingdeeCode"
          />
        </el-select>
      </el-form-item>





      <el-form-item label="促销政策:">
        <el-select
          v-model="detail.sales_policy"
          filterable
          class="w200"
          clearable
        >
          <el-option
            v-for="item in options.sales_policy"
            :key="item.sales_policy"
            :label="item.sales_policy_name"
            :value="item.sales_policy"
          />
        </el-select>
      </el-form-item>
      <el-form-item size="mini" label="是否体验">
        <el-radio v-model="detail.is_experience" label="0">否</el-radio>
        <el-radio v-model="detail.is_experience" label="1">是</el-radio>
      </el-form-item>
      <el-form-item
        v-if="detail.is_experience == 1"
        size="mini"
        label=" 体  验  人 "
        prop="experience_id"
      >
        <el-select
          v-model="detail.experience_id"
          class="w200"
          filterable
          clearable
          @change="changeExperiencc"
        >
          <el-option
            v-for="item in options.staff"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item size="mini" label="是否外联">
        <el-radio v-model="detail.is_outreach" label="0">否</el-radio>
        <el-radio v-model="detail.is_outreach" label="1">是</el-radio>
      </el-form-item>
      <el-form-item
        v-if="detail.is_outreach == 1"
        size="mini"
        label=" 外 联 人 "
        prop="outreahc_id"
      >
        <el-select
          v-model="detail.outreahc_id"
          class="w200"
          filterable
          clearable
          @change="changeOutreahc"
        >
          <el-option
            v-for="item in options.staff"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item size="mini" label="是否转介绍">
        <el-radio v-model="detail.is_introduction" label="0">否</el-radio>
        <el-radio v-model="detail.is_introduction" label="1">是</el-radio>
      </el-form-item>
      <el-form-item
        v-if="detail.is_introduction == 1"
        size="mini"
        label="转介绍人 "
        prop="introduction_id"
      >
        <el-select
          v-model="detail.introduction_id"
          class="w200"
          filterable
          clearable
          @change="changeIntroduction"
        >
          <el-option
            v-for="item in options.staff"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
	  <el-form-item size="mini" label="收入类型" prop="income_type">
	    <el-select
	      v-model="detail.income_type"
	      placeholder="请选择"
	      class="w200"
	    >
	      <el-option
	        v-for="item in options.income_type"
	        :key="item.id"
	        :label="item.name"
	        :value="item.id"
	      />
	    </el-select>
	  </el-form-item>
      <el-form-item label="订单描述:">
        <el-input
          v-model="detail.description"
          class="w200"
          placeholder="请填写订单描述"
          type="textarea"
        />
      </el-form-item>
      <div class="center">
        <el-form-item label="退货单商品:">
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            :disabled="detail.goods_type == 0"
            @click="handleProduct('add')"
            >新增</el-button
          >
        </el-form-item>
      </div>
      <div class="center">
        <el-table
          :data="goods"
          size="small"
          class="mb20"
          :cell-style="$style.cellStyle"
          :header-cell-style="$style.rowClass"
        >
          <el-table-column label="商品名称" >
            <template slot-scope="scope">
              <el-select
                v-model="goods[scope.$index]"
                size="mini"
                value-key="id"
                placeholder="请选择商品"
                filterable
                @focus="selectFocus"
                :remote-method="remoteMethod"
                @change="(val) => selectChange(val, scope.$index)"
              >
                <el-option
                  v-for="(item, index) in product"
                  :key="index"
                  :label="item.zu_name"
                  :value="item"
                  :disabled="goods.findIndex((val) => val.id === item.id) > -1"
                />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="商品名称-别名" >
            <template scope="{$index}">
              <el-input v-model="goods[$index].goods_alias_name" placeholder="请输入商品名称-别名" size = 'mini'></el-input>

            </template>
          </el-table-column>
          <el-table-column label="金蝶编码" prop="kingdee_code" width="160"/>
          <el-table-column label="标准单价" prop="goods_price" width="120"/>
          <el-table-column label="规格名称" prop="spec_name" width="120"/>
          <el-table-column label="* 是否赠品">
            <template scope="{$index}">
              <el-checkbox v-model="goods[$index].checked" @change="(value) => changeGift(value, $index)">是</el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="* 数量" >
            <template scope="{$index}">
              <el-input-number
              v-if=" goods[$index].kingdee_code != '101.12.098'"
                v-model="goods[$index].number "
                :min="0.0"
                :precision="2"
                size="mini"
                @change="(value) => handleChange(value, $index)"
              />
              <el-input-number
                    v-if=" goods[$index].kingdee_code == '101.12.098'"
                    v-model="goods[$index].number"
                    size="mini"
                    :precision="2"
                    @change="(value) => handleChange(value, $index)"
                  />
            </template>
          </el-table-column>

          <el-table-column label="* 含税单价" >
            <template scope="{$index}">
              <el-input-number
                v-if="goods[$index].is_gift == 0"
                v-model="goods[$index].pay_price"
                :min="0.0"
                :precision="2"
                size="mini"
                @change="(value) => priceChange(value, $index)"
              />
              <el-input-number
                v-else
                v-model="goods[$index].pay_price"
                :min="0.0"
                :precision="2"
                size="mini"
                disabled
                @change="(value) => priceChange(value, $index)"
              />
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80">
            <template slot-scope="{ row }">
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                circle
                @click="clickDel(row)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="center" style="text-align: right">
        <span>应退金额：{{ this.detail.total_amount }}元</span>
        <span class="ml20">实退金额：{{ this.detail.total_amount }}元</span>
      </div>
      <div class="btnBox">
        <el-button
          type="primary"
          size="mini"
          class="mainBtn"
          @click="submitForm('detail')"
          >完成，提交</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import { staffListS } from "@/api/staff";
import { shopDetail, shopMarket,shopStore } from "@/api/shop";
import { goodsList } from "@/api/goods";
import {
  refundMode,
  createRefundGoods,
  saleList,
  houseList,
} from "@/api/order";
class Detail {
  type = 2;
  shop_id = "";
  shop_name = "";
  uid = "";
  consignee = "";
  market_id = "";
  market_name = "";
  sale_id = ""; //开单人id
  sale_name = ""; //开单人名称
  sales_organization = ""; // 销售组织编码
  sales_name = ""; // 销售组织名称
  settlement_organization = ""; // 库存组织编码
  settlement_name = ""; // 库存组织名称
   delivery_name = ''; // 发货部门
  delivery_king = ''; // 发货部门编码
  house_code = ""; // 发货仓库
  house_code_name = "";
  is_experience = "0"; // 是否体验
  experience_id = ""; // 体验人id
  experience_name = ""; // 体验人姓名
  is_outreach = "0"; // 是否外联
  outreahc_id = ""; // 外联人id
  outreahc_name = ""; // 外联人姓名
  is_introduction = "0"; // 是否转介绍
  introduction_id = ""; // 转介绍id
  introduction_name = ""; // 转介绍姓名
  deal_time = ""; //	业务日期
  business_type = ""; //业务类型
  business_two_type = ""; //业务类型
  order_type = ""; //订单类型
  project_type = "";//项目类型
  income_type = ''; // 收入类型
  sales_policy = ""; //	促销政策
  description = ""; //	订单描述

  pay_amount = ""; //销售金额
  gift_amount = ""; //	预存赠送金额
  total_amount = 0; //合计金额

  goodsList = [];
}
class Rules {
  sale_id = [{ required: true, message: "请选择开单人", trigger: "change" }];
  sales_organization = [
    { required: true, message: "请选择销售组织", trigger: "change" },
  ];
  settlement_organization = [
    { required: true, message: "请选择库存组织", trigger: "change" },
  ];
  house_code = [
    { required: true, message: "请选择发货仓库", trigger: "change" },
  ];
  business_type = [
    { required: true, message: "请选择业务类型", trigger: "change" },
  ];
  order_type = [
    { required: true, message: "请选择订单类型", trigger: "change" },
  ];

  refund_type = [
    { required: true, message: "请选择收款类型", trigger: "change" },
  ];

  income_type = [
    { required: true, message: '请选择收入类型', trigger: 'change' }
  ];
  deal_time = [
    { required: true, message: "请选择业务日期", trigger: "change" },
  ];
  pay_amount = [
    { required: true, message: "请输入销售金额", trigger: "change" },
    {
      pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
      message: "最多保留两位小数",
      trigger: "change",
    },
  ];
  gift_amount = [
    { required: true, message: "请输入预存赠送金额", trigger: "change" },
    {
      pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
      message: "最多保留两位小数",
      trigger: "change",
    },
  ];
  shop_transfer_amount = [
    { required: true, message: "请输入店转金额", trigger: "change" },
    {
      pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
      message: "最多保留两位小数",
      trigger: "change",
    },
  ];
  experience_id = [
    { required: true, message: "请选择体验人", trigger: "change" },
  ];
  outreahc_id = [
    { required: true, message: "请选择外联人", trigger: "change" },
  ];
  introduction_id = [
    { required: true, message: "请选择转介绍人", trigger: "change" },
  ];
}
class Options {
  constructor() {
    this.uid = [];
    this.shop_id = [];
    this.market = [];
    this.business_type = [
      { id: 1, name: "疤痕" },
      { id: 2, name: "项目" },
      { id: 3, name: "青春痘" },
      { id: 4, name: "护肤品" }
    ];
    this.order_type = [
      { id: 1, name: "新单" },
      { id: 2, name: "补单" },
    ];

	this.income_type = [
	  { id: 1, name: '服务（各类疗程卡项及疗程卡项赠送产品）' },
	  { id: 2, name: '产品销售（单独付费购买产品）' },
	  { id: 3, name: '医美收入' },
	];

  this.project_type = [
	  { id: 1, name: '手术' },
	  { id: 2, name: '注射' },
	  { id: 3, name: '光电' },
    { id: 4, name: '生美皮肤' },
    { id: 5, name: '身体' },
	];
  this.business_two_type = [
      { id: 1, name: "身体项目" },
      { id: 2, name: "皮肤项目" },
  ];

    this.refundMode = [];
    this.staff = [];
    this.house = [];
    this.sales_policy = [];
  }
}
class Goods {
  id = "";
  name = "";
  kingdee_code = "";
  goods_price = "";
  spec_name = "";
  number = 1;
  unit_code = "";
  is_gift = "";
  checked = false;
  pay_price = "";
  house_code = "";
  house_code_name = "";
  goods_deal_time = "";
  descs = "";
}
export default {
  data() {
    return {
      detail: new Detail(),
      rules: new Rules(),
      goods: [new Goods()], // 已选列表
      options: new Options(), // 选项
      loading: false,
      product: [],
    };
  },
  mounted() {
    this.detail.shop_id = this.$route.query.detail.shop_id;
    this.detail.shop_name = this.$route.query.detail.shop_name;
    this.detail.uid = this.$route.query.detail.id;
    this.detail.customer_id = this.$route.query.detail.id;
    this.detail.consignee = this.$route.query.detail.customer_name;
    this.getOptions();
  },
  methods: {
    business_type(e){
      if(e==2){
        this.isShowBusinessTwoType = true;
        this.detail.business_two_type = 2;
      }else{
        this.isShowBusinessTwoType = false;
        this.detail.business_two_type  = "";
      }
    },
    // 获取选项
    getOptions() {
      // 组织
      shopMarket({ shopId: this.detail.shop_id }).then((res) => {
        this.options.market = res;
        this.detail.sales_organization = this.options.market[0].marketKingdeeCode
        this.detail.sales_name = this.options.market[0].marketName
        this.detail.settlement_organization = this.options.market[0].marketKingdeeCode
        this.detail.settlement_name = this.options.market[0].marketName
      });
      // 促销政策
      saleList().then((res) => {
        this.options.sales_policy = res.data.list;
      });
      // 该门店员工
      staffListS({ page: 1, limit: 100000, shopId: this.detail.shop_id }).then(
        (res) => {
          this.options.staff = res.list;
        }
      );
      //该门店仓库
      shopStore({page: 1, limit: 100000, shopId: this.detail.shop_id }).then(res => {
        this.options.house = res.list;
      })
      // 该门店详情
      shopDetail({ shopId: this.detail.shop_id }).then((res) => {
        this.detail.bank_number = res.bankCard;
        this.detail.market_id = res.marketId;
        this.detail.market_name = res.marketName;
        this.detail.delivery_name = res.shopName
        this.detail.delivery_king = res.keycode
      });
      this.getGoods()
    },
      //商品数据
      getGoods(name){
        goodsList({page: 1, limit: 100000,zu_name:name?name:''}).then((res) => {
         this.product = res.list;
        });
      },
    // 选择转开单人
    changeSale(e) {
      this.options.staff.forEach((item) => {
        if (e == item.id) {
          this.detail.sale_name = item.name;
        }
      });
    },
    // 选择销售组织
    changeSales(e) {
      this.options.market.forEach((item) => {
        if (e == item.marketKingdeeCode) {
          this.detail.sales_name = item.marketName;
        }
      });
    },
    // 选择库存组织
    changeSettlement(e) {
      this.options.market.forEach((item) => {
        if (e == item.marketKingdeeCode) {
          this.detail.settlement_name = item.marketName;
        }
      });
    },
    //
    // 选择仓库
    changeHouse(e) {
      this.options.house.forEach((item) => {
        if (e == item.kingdeeCode) {
          this.detail.house_code_name = item.name;
        }
      });
    },
     // 选择体验人
    changeExperiencc(e) {
      this.options.staff.forEach((item) => {
        if (e == item.id) {
          this.detail.experience_name = item.name;
        }
      });
    },
    // 选择外联人
    changeOutreahc(e) {
      this.options.staff.forEach((item) => {
        if (e == item.id) {
          this.detail.outreahc_name = item.name;
        }
      });
    },
    // 选择转介绍人
    changeIntroduction(e) {
      this.options.staff.forEach((item) => {
        if (e == item.id) {
          this.detail.introduction_name = item.name;
        }
      });
    },

    // 商品选择前
    selectFocus() {
      if (!this.detail.uid) return this.$message.warning("请先选择客户哦！");
    },
    // 搜索商品
    remoteMethod(e){
      this.getGoods(e)
    },
    // 商品操作
    handleProduct(type) {
      switch (type) {
        case "add":
          this.goods.push(new Goods());
          break;
      }
    },
    // 是否赠品
    changeGift(value, index) {
      this.goods[index].pay_price = 0;
      this.goods[index].number = 0;
      this.goods[index].is_gift = this.goods[index].checked?1:0
      this.priceChange(this.goods[index].pay_price, index);
    },
    // 商品修改
    selectChange(val, index) {
      // 选中商品时，默认规格为第一项
      const { goods_price, id, kingdee_code, spec_name, unit_code,is_project } = val;
      this.goods[index].kingdee_code = kingdee_code;
      this.goods[index].id = id;
      this.goods[index].goods_price = goods_price;
      this.goods[index].spec_name = spec_name;
      this.goods[index].unit_code = unit_code;
      this.goods[index].is_project = is_project;
      this.goods[index].is_gift = 0
    },
    // 商品数量
    handleChange(value, index) {
      this.goods[index].littleCount = (
        value * this.goods[index].pay_price
      ).toFixed(2);
      this.getSummaries();
    },

    // 销售价格
    priceChange(value, index) {
      this.goods[index].littleCount = (
        value * this.goods[index].number
      ).toFixed(2);
      this.getSummaries();
    },
    // 合计计算
    getSummaries() {
      this.detail.total_amount = 0;
      this.goods.forEach((item) => {
        this.detail.total_amount = (
          parseFloat(this.detail.total_amount ? this.detail.total_amount : 0) +
          parseFloat(item.littleCount ? item.littleCount : 0)
        ).toFixed(2);
      });
    },
    // 同步选中商品
    getDetailGoods() {
      let list = [];
      list = this.goods.map((i) => {
        return i.id;
      });
      let obj = {};
      for (let key in list) {
        obj[key] = list[key];
      }
      this.detail.goodsList = Object.keys(obj).map((val) => ({
        id: obj[val],
      }));
    },
    // 删除单商品
    clickDel(row) {
      this.goods = this.goods.filter((item) => item.id !== row.id);
      this.getSummaries();
    },
    // 提交
    submitForm(formName) {
      // console.log(this.detail);
      this.detail.goodsList = this.goods.filter((val) => val.id);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.checking();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 验证
    checking() {
      if (this.detail.goodsList == 0) {
        this.$message({
          message: "请添加退货商品",
          type: "error",
        });
      } else {
        let number = 0;
        let is_gift = 0;
        let pay_price = 0;
        this.detail.goodsList.forEach((item) => {
          if (!item.number) {
            number += 1;
          }
          if (item.is_gift === "") {
            is_gift += 1;
          }
          if (item.pay_price === "") {
            pay_price += 1;
          }
          item["house_code"] = this.detail.house_code;
          item["house_code_name"] = this.detail.house_code_name;
          item["goods_deal_time"] = this.detail.deal_time;
          item["descs"] = "退货";
        });
        let totalAll =
          parseFloat(this.detail.pay_amount) +
          parseFloat(this.detail.gift_amount)+
          parseFloat(this.detail.shop_transfer_amount);
        if (number > 0 || is_gift > 0 || pay_price > 0) {
          this.$message({
            message: "请完善退货商品列表",
            type: "error",
          });
        } else if (totalAll != this.detail.total_amount) {
          this.$message({
            message: "销售金额 + 预存赠送金额 + 店转金额 = 应退金额?",
            type: "error",
          });
        } else {
          this.hint("添加");
        }
      }
    },
    // 提示
    hint(text) {
      this.$confirm(`确定${text}此退货订单吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.confirm();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 提交
    async confirm() {
      const loading = this.$loading({
        lock: true,
        text: "提交中，请稍后...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      try {
        const res = await createRefundGoods({ ...this.detail });
        loading.close();
        this.$message({
          type: "success",
          message: res.msg
        });
        this.back();
      } catch (e) {
        loading.close();
      }
    },
    // 返回
    back() {
      const query = {
        detail: this.$route.query.detail,
        id: this.$route.query.detail.id,
        activeName: "fifth",
      };
      this.$router.push({ name: "客户详情", query });
    },
  },
};
</script>

<style lang="scss" scoped>
.return {
  position: fixed;
  right: 20px;
  top: 71px;
  z-index: 999;
  .backImg {
    margin-right: 14px;
    width: 32px;
    height: 100%;
    cursor: pointer;
    vertical-align: middle;
  }
}
.formBox {
  display: flex;
  flex-wrap: wrap;
  .el-form-item {
    width: 33%;
  }
}
.el-radio {
  margin-right: 8px;
}
.center {
  width: 100% !important;
}
.btnBox {
  width: 100%;
  text-align: center;
}
</style>
